import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import TerminalInput from './TerminalInput';
import TerminalOutput from './TerminalOutput';

const TerminalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  padding: 10px;
`;

const TerminalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #2d2d2d;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const TerminalTitle = styled.div`
  color: #f8f8f8;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
`;

const TerminalBody = styled.div`
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const WelcomeMessage = styled.div`
  color: #4ec9b0;
  margin-bottom: 20px;
  line-height: 1.5;
`;

interface CommandHistory {
  command: string;
  output: string;
}

const Terminal: React.FC = () => {
  const [commandHistory, setCommandHistory] = useState<CommandHistory[]>([]);
  const [visitCount, setVisitCount] = useState<number>(0);
  const terminalBodyRef = useRef<HTMLDivElement>(null);
  const [lastSuggestion, setLastSuggestion] = useState<string | null>(null);

  // Liste des commandes disponibles
  const availableCommands = [
    'help',
    'about',
    'skills',
    'experience',
    'education',
    'contact',
    'download-cv',
    'clear'
  ];

  // Fonction pour trouver des commandes similaires
  const findSimilarCommand = (command: string): string | null => {
    // Si la commande est vide, ne pas suggérer
    if (!command.trim()) return null;
    
    // Convertir en minuscules pour la comparaison
    const lowerCommand = command.toLowerCase();
    
    // Vérifier si une commande similaire existe
    for (const availableCommand of availableCommands) {
      // Vérifier si la commande est similaire (inclut la commande ou vice versa)
      if (availableCommand.includes(lowerCommand) || lowerCommand.includes(availableCommand)) {
        return availableCommand;
      }
      
      // Vérifier la distance de Levenshtein (similitude de chaînes)
      if (levenshteinDistance(lowerCommand, availableCommand) <= 2) {
        return availableCommand;
      }
    }
    
    return null;
  };

  // Fonction pour calculer la distance de Levenshtein (mesure de similitude entre deux chaînes)
  const levenshteinDistance = (a: string, b: string): number => {
    const matrix: number[][] = [];

    // Initialiser la matrice
    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }
    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Remplir la matrice
    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        const cost = a[j - 1] === b[i - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,      // suppression
          matrix[i][j - 1] + 1,      // insertion
          matrix[i - 1][j - 1] + cost // substitution
        );
      }
    }

    return matrix[b.length][a.length];
  };

  useEffect(() => {
    // Récupérer le compteur de visites du localStorage
    const storedVisitCount = localStorage.getItem('visitCount');
    const newVisitCount = storedVisitCount ? parseInt(storedVisitCount, 10) + 1 : 1;
    setVisitCount(newVisitCount);
    localStorage.setItem('visitCount', newVisitCount.toString());
  }, []);

  useEffect(() => {
    // Faire défiler vers le bas lorsque l'historique des commandes change
    if (terminalBodyRef.current) {
      terminalBodyRef.current.scrollTop = terminalBodyRef.current.scrollHeight;
    }
  }, [commandHistory]);

  const handleCommand = (command: string) => {
    // Logique simple pour traiter les commandes
    let output = '';
    
    if (command.toLowerCase() === 'help') {
      output = `
Commandes disponibles:
- help: Affiche cette aide
- about: À propos de moi
- skills: Mes compétences
- experience: Mon expérience professionnelle
- education: Ma formation
- contact: Comment me contacter
- download-cv: Télécharger mon CV en PDF
- clear: Efface l'écran
- !!: Exécute la dernière commande suggérée
      `;
    } else if (command.toLowerCase() === 'about') {
      output = `
Je suis un développeur passionné par la création d'applications web modernes et performantes.
      `;
    } else if (command.toLowerCase() === 'skills') {
      output = `
Mes compétences techniques:
- Frontend: React, TypeScript, HTML, CSS
- Backend: Node.js, Express
- Autres: Docker, Git, CI/CD
      `;
    } else if (command.toLowerCase() === 'experience') {
      output = `
Expérience professionnelle:
- 2020-Présent: Développeur Full Stack chez XYZ
- 2018-2020: Développeur Frontend chez ABC
      `;
    } else if (command.toLowerCase() === 'education') {
      output = `
Formation:
- 2018: Master en Informatique
- 2016: Licence en Informatique
      `;
    } else if (command.toLowerCase() === 'contact') {
      output = `
Contact:
- Email: exemple@email.com
- LinkedIn: linkedin.com/in/exemple
- GitHub: github.com/exemple
      `;
    } else if (command.toLowerCase() === 'download-cv') {
      // Fonction pour vérifier si le fichier existe
      const checkFileExists = async (url: string): Promise<boolean> => {
        try {
          const response = await fetch(url, { method: 'HEAD' });
          return response.ok;
        } catch (error) {
          return false;
        }
      };

      // Vérifier si le CV existe et le télécharger
      checkFileExists('/assets/cv.pdf').then((exists) => {
        if (exists) {
          const link = document.createElement('a');
          link.href = '/assets/cv.pdf';
          link.download = 'cv.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          
          // Mettre à jour l'historique des commandes avec un message de succès
          setCommandHistory([...commandHistory, { 
            command, 
            output: `Téléchargement de votre CV en cours...` 
          }]);
        } else {
          // Mettre à jour l'historique des commandes avec un message d'erreur
          setCommandHistory([...commandHistory, { 
            command, 
            output: `Erreur: Le fichier CV n'est pas disponible pour le moment. Veuillez réessayer plus tard.` 
          }]);
        }
      });
      
      // Retourner immédiatement pour éviter d'ajouter deux fois à l'historique
      return;
    } else if (command.toLowerCase() === 'clear') {
      setCommandHistory([]);
      return;
    } else if (command.trim() === '') {
      output = '';
    } else if (command === '!!' && lastSuggestion) {
      // Exécuter la dernière commande suggérée
      handleCommand(lastSuggestion);
      return;
    } else {
      // Rechercher une commande similaire
      const similarCommand = findSimilarCommand(command);
      
      if (similarCommand) {
        setLastSuggestion(similarCommand);
        output = `Commande non reconnue: ${command}. 
        
Vouliez-vous dire "${similarCommand}" ? 
Vous pouvez l'exécuter en tapant: ${similarCommand}
Ou tapez "!!" pour exécuter directement la commande suggérée.`;
      } else {
        setLastSuggestion(null);
        output = `Commande non reconnue: ${command}. Tapez 'help' pour voir les commandes disponibles.`;
      }
    }

    setCommandHistory([...commandHistory, { command, output }]);
  };

  return (
    <TerminalContainer>
      <TerminalHeader>
        <TerminalTitle>Terminal CV</TerminalTitle>
      </TerminalHeader>
      <TerminalBody ref={terminalBodyRef}>
        <WelcomeMessage>
          Bienvenue sur mon Terminal CV interactif! 👋<br />
          Vous êtes le visiteur #{visitCount}.<br />
          Tapez 'help' pour voir les commandes disponibles.
        </WelcomeMessage>
        
        {commandHistory.map((item, index) => (
          <TerminalOutput 
            key={index} 
            command={item.command} 
            output={item.output} 
          />
        ))}
        
        <TerminalInput onCommand={handleCommand} />
      </TerminalBody>
    </TerminalContainer>
  );
};

export default Terminal; 