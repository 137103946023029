import React from 'react';
import styled from 'styled-components';

const OutputContainer = styled.div`
  margin-bottom: 15px;
`;

const CommandLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const Prompt = styled.span`
  color: #569cd6;
  margin-right: 10px;
  font-weight: 500;
`;

const Command = styled.span`
  color: #f8f8f8;
`;

const OutputText = styled.pre`
  color: #dcdcaa;
  margin: 0;
  padding: 0 0 0 20px;
  white-space: pre-wrap;
  font-family: 'Fira Code', monospace;
  font-size: 14px;
  line-height: 1.5;
`;

interface TerminalOutputProps {
  command: string;
  output: string;
}

const TerminalOutput: React.FC<TerminalOutputProps> = ({ command, output }) => {
  return (
    <OutputContainer>
      <CommandLine>
        <Prompt>$</Prompt>
        <Command>{command}</Command>
      </CommandLine>
      {output && <OutputText>{output}</OutputText>}
    </OutputContainer>
  );
};

export default TerminalOutput; 