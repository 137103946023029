import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Terminal from './components/Terminal/Terminal';
import styled from 'styled-components';

const AppContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
`;

const App: React.FC = () => {
  return (
    <Router>
      <AppContainer>
        <Routes>
          <Route path="/" element={<Terminal />} />
        </Routes>
      </AppContainer>
    </Router>
  );
};

export default App; 